/*
Template Name: UBold Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Components
*/
/* =============
  == Components List==

   - Buttons
   - Checkbox and radio
   - Panels
   - Portlets
   - Progressbars
   - Tables
   - Widgets
   - Form elements
   - Sweet Alert

   
   
============= */
/* =============
   Buttons
============= */
.btn {
  border-radius: 3px;
  outline: none !important;
}
.btn-md {
  padding: 8px 16px;
}
.btn-primary,
.btn-success,
.btn-default,
.btn-info,
.btn-warning,
.btn-danger,
.btn-inverse,
.btn-purple,
.btn-pink {
  color: #ffffff !important;
}
.btn-default,
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open > .dropdown-toggle.btn-default {
  background-color: #5fbeaa !important;
  border: 1px solid #5fbeaa !important;
}
.btn-white,
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.btn-white.focus,
.btn-white:active,
.btn-white:focus,
.btn-white:hover,
.open > .dropdown-toggle.btn-white {
  border: 1px solid #eaeaea !important;
  background-color: #ffffff;
  color: #4c5667;
}
.btn-white:hover,
.btn-white:hover:hover,
.btn-white:focus:hover,
.btn-white:active:hover,
.btn-white.active:hover,
.btn-white.focus:hover,
.btn-white:active:hover,
.btn-white:focus:hover,
.btn-white:hover:hover,
.open > .dropdown-toggle.btn-white:hover {
  background-color: #f9f9f9;
}
.btn-white:focus,
.btn-white:hover:focus,
.btn-white:focus:focus,
.btn-white:active:focus,
.btn-white.active:focus,
.btn-white.focus:focus,
.btn-white:active:focus,
.btn-white:focus:focus,
.btn-white:hover:focus,
.open > .dropdown-toggle.btn-white:focus {
  background-color: #f9f9f9;
}
.btn-white:active,
.btn-white:hover:active,
.btn-white:focus:active,
.btn-white:active:active,
.btn-white.active:active,
.btn-white.focus:active,
.btn-white:active:active,
.btn-white:focus:active,
.btn-white:hover:active,
.open > .dropdown-toggle.btn-white:active {
  background-color: #f9f9f9;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: #5d9cec !important;
  border: 1px solid #5d9cec !important;
}
.btn-success,
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success {
  background-color: #81c868 !important;
  border: 1px solid #81c868 !important;
}
.btn-info,
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info {
  background-color: #34d3eb !important;
  border: 1px solid #34d3eb !important;
}
.btn-warning,
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
  background-color: #ffbd4a !important;
  border: 1px solid #ffbd4a !important;
}
.btn-danger,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
  background-color: #f05050 !important;
  border: 1px solid #f05050 !important;
}
.btn-inverse,
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #4c5667 !important;
  border: 1px solid #4c5667 !important;
  color: #ffffff;
}
.btn-purple,
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
  background-color: #7266ba !important;
  border: 1px solid #7266ba !important;
  color: #ffffff;
}
.btn-pink,
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active {
  background-color: #fb6d9d !important;
  border: 1px solid #fb6d9d !important;
  color: #ffffff;
}
.open > .dropdown-toggle.btn-primary.btn-custom,
.open > .dropdown-toggle.btn-success.btn-custom,
.open > .dropdown-toggle.btn-info.btn-custom,
.open > .dropdown-toggle.btn-warning.btn-custom,
.open > .dropdown-toggle.btn-danger.btn-custom,
.open > .dropdown-toggle.btn-default.btn-custom {
  border-width: 2px !important;
  color: #ffffff !important;
}
.open > .dropdown-toggle.btn-white.btn-custom {
  border-width: 2px !important;
}
.btn-custom.btn-default {
  color: #5fbeaa !important;
}
.btn-custom.btn-primary {
  color: #5d9cec !important;
}
.btn-custom.btn-success {
  color: #81c868 !important;
}
.btn-custom.btn-info {
  color: #34d3eb !important;
}
.btn-custom.btn-warning {
  color: #ffbd4a !important;
}
.btn-custom.btn-danger {
  color: #f05050 !important;
}
.btn-custom.btn-inverse {
  color: #4c5667 !important;
}
.btn-custom.btn-purple {
  color: #7266ba !important;
}
.btn-custom.btn-white {
  color: #4c5667 !important;
}
.btn-custom.btn-white:hover,
.btn-custom.btn-white:focus,
.btn-custom.btn-white:active {
  color: #4c5667 !important;
  background-color: #f4f8fb !important;
}
.btn-custom.btn-pink {
  color: #fb6d9d !important;
}
.btn-rounded {
  border-radius: 2em !important;
  padding: 6px 20px;
}
.btn-rounded .btn-label {
  padding: 7px 15px 7px 20px;
  margin-left: -20px;
}
.btn-rounded .btn-label-right {
  margin-right: -20px;
  margin-left: 12px;
}
.btn-custom {
  -moz-border-radius: 2px;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -webkit-border-radius: 2px;
  -webkit-transition: all 400ms ease-in-out;
  background: transparent;
  background-color: transparent !important;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  border-width: 2px !important;
  font-weight: 600;
  transition: all 400ms ease-in-out;
  background-clip: inherit;
}
.btn-custom:hover {
  color: #ffffff !important;
  border-width: 2px !important;
}
.btn-custom:focus {
  color: #ffffff !important;
  border-width: 2px !important;
}
.btn-label {
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 3px 0 0 3px;
  margin: -7px -13px;
  margin-right: 12px;
}
.btn-label-right {
  margin-left: 12px;
  margin-right: -13px;
  border-radius: 0px 3px 3px 0px;
}
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}
/* File Upload */
.fileupload {
  overflow: hidden;
  position: relative;
}
.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
/* Social Buttons */
.btn-facebook {
  color: #ffffff !important;
  background-color: #3b5998 !important;
}
.btn-twitter {
  color: #ffffff !important;
  background-color: #00aced !important;
}
.btn-linkedin {
  color: #ffffff !important;
  background-color: #007bb6 !important;
}
.btn-dribbble {
  color: #ffffff !important;
  background-color: #ea4c89 !important;
}
.btn-googleplus {
  color: #ffffff !important;
  background-color: #dd4b39 !important;
}
.btn-instagram {
  color: #ffffff !important;
  background-color: #517fa4 !important;
}
.btn-pinterest {
  color: #ffffff !important;
  background-color: #cb2027 !important;
}
.btn-dropbox {
  color: #ffffff !important;
  background-color: #007ee5 !important;
}
.btn-flickr {
  color: #ffffff !important;
  background-color: #ff0084 !important;
}
.btn-tumblr {
  color: #ffffff !important;
  background-color: #32506d !important;
}
.btn-skype {
  color: #ffffff !important;
  background-color: #00aff0 !important;
}
.btn-youtube {
  color: #ffffff !important;
  background-color: #bb0000 !important;
}
.btn-github {
  color: #ffffff !important;
  background-color: #171515 !important;
}
/* =============
   Checkbox and Radios
============= */
.checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}
.checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #cccccc;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}
.checkbox label::after {
  color: #555555;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 0;
  width: 16px;
}
.checkbox input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
  outline: thin dotted;
}
.checkbox input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: 'FontAwesome';
}
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}
.checkbox.checkbox-single label {
  height: 17px;
}
.checkbox-custom input[type="checkbox"]:checked + label::before {
  background-color: #5fbeaa;
  border-color: #5fbeaa;
}
.checkbox-custom input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #5d9cec;
  border-color: #5d9cec;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #f05050;
  border-color: #f05050;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #34d3eb;
  border-color: #34d3eb;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffbd4a;
  border-color: #ffbd4a;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #81c868;
  border-color: #81c868;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-purple input[type="checkbox"]:checked + label::before {
  background-color: #7266ba;
  border-color: #7266ba;
}
.checkbox-purple input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-pink input[type="checkbox"]:checked + label::before {
  background-color: #fb6d9d;
  border-color: #fb6d9d;
}
.checkbox-pink input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-inverse input[type="checkbox"]:checked + label::before {
  background-color: #4c5667;
  border-color: #4c5667;
}
.checkbox-inverse input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
/* Radios */
.radio {
  padding-left: 20px;
}
.radio label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}
.radio label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #cccccc;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 17px;
  outline: none !important;
}
.radio label::after {
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #555555;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 11px;
  left: 3px;
  margin-left: -20px;
  position: absolute;
  top: 3px;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 11px;
}
.radio input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: thin dotted;
}
.radio input[type="radio"]:checked + label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio.radio-inline {
  margin-top: 0;
}
.radio.radio-single label {
  height: 17px;
}
.radio-custom input[type="radio"] + label::after {
  background-color: #5fbeaa;
}
.radio-custom input[type="radio"]:checked + label::before {
  border-color: #5fbeaa;
}
.radio-custom input[type="radio"]:checked + label::after {
  background-color: #5fbeaa;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #5d9cec;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #5d9cec;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #5d9cec;
}
.radio-danger input[type="radio"] + label::after {
  background-color: #f05050;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #f05050;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #f05050;
}
.radio-info input[type="radio"] + label::after {
  background-color: #34d3eb;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #34d3eb;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #34d3eb;
}
.radio-warning input[type="radio"] + label::after {
  background-color: #ffbd4a;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffbd4a;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffbd4a;
}
.radio-success input[type="radio"] + label::after {
  background-color: #81c868;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #81c868;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #81c868;
}
.radio-purple input[type="radio"] + label::after {
  background-color: #7266ba;
}
.radio-purple input[type="radio"]:checked + label::before {
  border-color: #7266ba;
}
.radio-purple input[type="radio"]:checked + label::after {
  background-color: #7266ba;
}
.radio-pink input[type="radio"] + label::after {
  background-color: #fb6d9d;
}
.radio-pink input[type="radio"]:checked + label::before {
  border-color: #fb6d9d;
}
.radio-pink input[type="radio"]:checked + label::after {
  background-color: #fb6d9d;
}
/* =============
   Panels
============= */
.panel {
  border: none;
  margin-bottom: 20px;
}
.panel .panel-body {
  padding: 20px;
}
.panel .panel-body p {
  margin: 0px;
}
.panel .panel-body p + p {
  margin-top: 15px;
}
.panel-heading {
  border: none !important;
  padding: 10px 20px;
}
.panel-default > .panel-heading {
  background-color: #f4f8fb;
  border-bottom: none;
  color: #797979;
}
.panel-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.panel-footer {
  background: #f4f8fb;
  border-top: 0px;
}
.panel-color .panel-title {
  color: #ffffff;
}
.panel-custom > .panel-heading {
  background-color: #5fbeaa;
}
.panel-primary > .panel-heading {
  background-color: #5d9cec;
}
.panel-success > .panel-heading {
  background-color: #81c868;
}
.panel-info > .panel-heading {
  background-color: #34d3eb;
}
.panel-warning > .panel-heading {
  background-color: #ffbd4a;
}
.panel-danger > .panel-heading {
  background-color: #f05050;
}
.panel-purple > .panel-heading {
  background-color: #7266ba;
}
.panel-pink > .panel-heading {
  background-color: #fb6d9d;
}
.panel-inverse > .panel-heading {
  background-color: #4c5667;
}
.panel-border .panel-heading {
  background-color: #ffffff;
  border-top: 3px solid #DADFE2 !important;
  padding: 10px 20px 0px;
}
.panel-border .panel-body {
  padding: 15px 20px 20px 20px;
}
.panel-border.panel-custom .panel-heading {
  border-color: #5fbeaa !important;
  color: #5fbeaa !important;
}
.panel-border.panel-primary .panel-heading {
  border-color: #5d9cec !important;
  color: #5d9cec !important;
}
.panel-border.panel-success .panel-heading {
  border-color: #81c868 !important;
  color: #81c868 !important;
}
.panel-border.panel-info .panel-heading {
  border-color: #34d3eb !important;
  color: #34d3eb !important;
}
.panel-border.panel-warning .panel-heading {
  border-color: #ffbd4a !important;
  color: #ffbd4a !important;
}
.panel-border.panel-danger .panel-heading {
  border-color: #f05050 !important;
  color: #f05050 !important;
}
.panel-border.panel-purple .panel-heading {
  border-color: #7266ba !important;
  color: #7266ba !important;
}
.panel-border.panel-pink .panel-heading {
  border-color: #fb6d9d !important;
  color: #fb6d9d !important;
}
.panel-border.panel-inverse .panel-heading {
  border-color: #4c5667 !important;
  color: #4c5667 !important;
}
.panel-group .panel .panel-heading a[data-toggle=collapse].collapsed:before {
  content: '\f0d7';
}
.panel-group .panel .panel-heading .accordion-toggle.collapsed:before {
  content: '\f0d7';
}
.panel-group .panel .panel-heading a[data-toggle=collapse] {
  display: block;
}
.panel-group .panel .panel-heading a[data-toggle=collapse]:before {
  content: '\f0d8';
  display: block;
  float: right;
  font-family: 'FontAwesome';
  font-size: 14px;
  text-align: right;
  width: 25px;
}
.panel-group .panel .panel-heading .accordion-toggle {
  display: block;
}
.panel-group .panel .panel-heading .accordion-toggle:before {
  content: '\f0d8';
  display: block;
  float: right;
  font-family: 'FontAwesome';
  font-size: 14px;
  text-align: right;
  width: 25px;
}
.panel-group .panel .panel-heading + .panel-collapse .panel-body {
  border-top: none;
}
.panel-group .panel-heading {
  padding: 12px 26px;
}
.panel-group.panel-group-joined .panel + .panel {
  border-top: 1px solid #eeeeee;
  margin-top: 0;
}
.panel-group-joined .panel-group .panel + .panel {
  border-top: 1px solid #eeeeee;
  margin-top: 0;
}
.panel .nav-pills li a {
  color: #4c5667 !important;
}
.panel .nav-pills li.active a {
  color: #ffffff !important;
}
/* =============
   Portlets
============= */
.portlet {
  background: #ffffff;
  border: 1px solid rgba(54, 64, 74, 0.05);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
}
.portlet .portlet-heading {
  -webkit-border-radius: 3px 3px 0px 0px;
  border-radius: 3px 3px 0px 0px;
  -moz-border-radius: 3px 3px 0px 0px;
  background-clip: padding-box;
  color: #ffffff;
  padding: 12px 20px;
}
.portlet .portlet-heading .portlet-title {
  color: #ffffff;
  float: left;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.portlet .portlet-heading .portlet-widgets {
  display: inline-block;
  float: right;
  font-size: 15px;
  line-height: 30px;
  padding-left: 15px;
  position: relative;
  text-align: right;
}
.portlet .portlet-heading .portlet-widgets .divider {
  margin: 0 5px;
}
.portlet .portlet-heading .portlet-widgets .collapsed .ion-minus-round:before {
  content: "\f217" !important;
}
.portlet .portlet-heading a {
  color: #999999;
}
.portlet .portlet-body {
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  background: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px;
}
.portlet-default .portlet-title {
  color: #797979 !important;
}
.portlet .portlet-heading.bg-custom a,
.portlet .portlet-heading.bg-purple a,
.portlet .portlet-heading.bg-info a,
.portlet .portlet-heading.bg-success a,
.portlet .portlet-heading.bg-primary a,
.portlet .portlet-heading.bg-danger a,
.portlet .portlet-heading.bg-warning a,
.portlet .portlet-heading.bg-inverse a,
.portlet .portlet-heading.bg-pink a {
  color: #ffffff;
}
.panel-disabled {
  background: rgba(243, 242, 241, 0.5);
  bottom: 15px;
  left: 0px;
  position: absolute;
  right: -5px;
  top: 0;
}
.loader-1 {
  -moz-animation: loaderAnimate 1000ms linear infinite;
  -o-animation: loaderAnimate 1000ms linear infinite;
  -webkit-animation: loaderAnimate 1000ms linear infinite;
  animation: loaderAnimate 1000ms linear infinite;
  clip: rect(0, 30px, 30px, 15px);
  height: 30px;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: 30px;
}
.loader-1:after {
  -moz-animation: loaderAnimate2 1000ms ease-in-out infinite;
  -o-animation: loaderAnimate2 1000ms ease-in-out infinite;
  -webkit-animation: loaderAnimate2 1000ms ease-in-out infinite;
  animation: loaderAnimate2 1000ms ease-in-out infinite;
  border-radius: 50%;
  clip: rect(0, 30px, 30px, 15px);
  content: '';
  height: 30px;
  position: absolute;
  width: 30px;
}
/* =============
   Progressbars
============= */
.progress {
  -webkit-box-shadow: none !important;
  background-color: #ebeff2;
  box-shadow: none !important;
  height: 10px;
  margin-bottom: 18px;
  overflow: hidden;
}
.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
}
.progress.progress-sm {
  height: 5px !important;
}
.progress.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}
.progress.progress-md {
  height: 15px !important;
}
.progress.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}
.progress.progress-lg {
  height: 20px !important;
}
.progress.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}
.progress-bar-primary {
  background-color: #5d9cec;
}
.progress-bar-success {
  background-color: #81c868;
}
.progress-bar-info {
  background-color: #34d3eb;
}
.progress-bar-warning {
  background-color: #ffbd4a;
}
.progress-bar-danger {
  background-color: #f05050;
}
.progress-bar-inverse {
  background-color: #4c5667;
}
.progress-bar-purple {
  background-color: #7266ba;
}
.progress-bar-pink {
  background-color: #fb6d9d;
}
.progress-bar-custom {
  background-color: #5fbeaa;
}
.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: animationProgress;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: animationProgress;
  transition: 5s all;
}
/* Progressbar Vertical */
.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}
.progress-vertical .progress-bar {
  width: 100%;
}
.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 10px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}
.progress-vertical-bottom .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.progress-vertical.progress-sm,
.progress-vertical-bottom.progress-sm {
  width: 5px !important;
}
.progress-vertical.progress-sm .progress-bar,
.progress-vertical-bottom.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}
.progress-vertical.progress-md,
.progress-vertical-bottom.progress-md {
  width: 15px !important;
}
.progress-vertical.progress-md .progress-bar,
.progress-vertical-bottom.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}
.progress-vertical.progress-lg,
.progress-vertical-bottom.progress-lg {
  width: 20px !important;
}
.progress-vertical.progress-lg .progress-bar,
.progress-vertical-bottom.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}
/* =============
   Tables
============= */
.table {
  margin-bottom: 10px;
}
.table-striped > tbody > tr:nth-of-type(odd),
.table-hover > tbody > tr:hover,
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f4f8fb !important;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > thead > tr > th,
.table-bordered {
  border-top: 1px solid #ebeff2;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ebeff2;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ebeff2;
}
tbody {
  color: #797979;
}
th {
  color: #666666;
  font-weight: 600;
}
.table-bordered {
  border: 1px solid #ebeff2;
}
table.focus-on tbody tr.focused th {
  background-color: #5fbeaa;
  color: #ffffff;
}
table.focus-on tbody tr.focused td {
  background-color: #5fbeaa;
  color: #ffffff;
}
.table-rep-plugin .table-responsive {
  border: none !important;
}
.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: normal;
}
.table-rep-plugin .checkbox-row {
  padding-left: 40px;
}
.table-rep-plugin .checkbox-row label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}
.table-rep-plugin .checkbox-row label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #cccccc;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}
.table-rep-plugin .checkbox-row label::after {
  color: #555555;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: -1px;
  width: 16px;
}
.table-rep-plugin .checkbox-row input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: 'FontAwesome';
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::before {
  background-color: #5fbeaa;
  border-color: #5fbeaa;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.fixed-table-container tbody .selected td {
  background-color: #F4F8FB;
}
.modal-block {
  background: transparent;
  margin: 40px auto;
  max-width: 600px;
  padding: 0;
  position: relative;
  text-align: left;
}
/* Data table */
#datatable-editable .actions a {
  padding: 5px;
}
#datatable-editable .form-control {
  background-color: #ffffff;
  width: 100%;
}
#datatable-editable .fa-trash-o {
  color: #f05050;
}
#datatable-editable .fa-times {
  color: #f05050;
}
#datatable-editable .fa-pencil {
  color: #29b6f6;
}
#datatable-editable .fa-save {
  color: #33b86c;
}
#datatable td {
  font-weight: normal;
}
div.dataTables_paginate ul.pagination {
  margin-top: 30px;
}
div.dataTables_info {
  padding-top: 38px;
}
.dt-buttons {
  float: left;
}
div#datatable-buttons_info {
  float: left;
}
/* Footable */
.footable-odd {
  background-color: #ffffff;
}
.footable-detail-show {
  background-color: #ebeff2;
}
.footable-row-detail {
  background-color: #F0F4F7;
}
/* Bootstrap Table */
table[data-toggle="table"] {
  display: none;
}
.fixed-table-pagination .pagination-detail,
.fixed-table-pagination div.pagination {
  margin-top: 20px;
}
.fixed-table-container {
  border: 1px solid #ebeff2 !important;
}
.bootstrap-table .table > thead > tr > th {
  border-bottom: 2px solid #ebeff2;
  background: #ebeff2;
}
.fixed-table-container thead th .th-inner {
  padding: 9px 12px;
}
.bootstrap-table .table,
.bootstrap-table .table > tbody > tr > td,
.bootstrap-table .table > tbody > tr > th,
.bootstrap-table .table > tfoot > tr > td,
.bootstrap-table .table > tfoot > tr > th,
.bootstrap-table .table > thead > tr > td {
  padding: 8px 12px !important;
}
/* Tablesaw */
.tablesaw thead {
  background: #ebeff2;
  background-image: none;
  border: none;
}
.tablesaw thead th {
  text-shadow: none;
  letter-spacing: 0.06em;
}
.tablesaw thead tr:first-child th {
  padding-top: 1.1em;
  padding-bottom: 0.9em;
}
.tablesaw thead tr:first-child th {
  font-weight: 600;
  font-family: inherit;
  border: none;
}
.tablesaw td,
.tablesaw tbody th {
  font-size: inherit;
  line-height: inherit;
  padding: 10px !important;
}
.tablesaw-stack tbody tr,
.tablesaw tbody tr {
  border-bottom: none;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after,
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  font-family: FontAwesome;
  font-size: 10px;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
  content: "\f176";
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  content: "\f175";
}
.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
  font-size: 8px;
  padding-right: 10px;
}
.tablesaw-swipe .tablesaw-cell-persist {
  box-shadow: none;
}
.tablesaw-enhanced .tablesaw-bar .btn {
  text-shadow: none;
  background-image: none;
}
.tablesaw-enhanced .tablesaw-bar .btn.btn-select:hover {
  background: #ffffff;
}
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .btn:active {
  color: #5fbeaa !important;
  background-color: #ebeff2;
  outline: none !important;
  box-shadow: none !important;
  background-image: none;
}
/* Fixed table */
.fixedHeader-floating {
  top: 60px !important;
}
/* Key Table */
table.dataTable th.focus,
table.dataTable td.focus {
  outline: 3px solid #5fbeaa !important;
  outline-offset: -1px;
}
/* ColVid Tables */
div.ColVis {
  float: none;
  margin-right: 30px;
}
button.ColVis_Button,
.ColVis_Button:hover {
  float: none;
  border-radius: 3px;
  outline: none !important;
  background: none;
  box-shadow: none;
  color: #ffffff !important;
  background-color: #5fbeaa !important;
  border: 1px solid #5fbeaa !important;
}
.dataTables_length {
  float: left;
}
div.ColVis_collectionBackground {
  background-color: transparent;
}
ul.ColVis_collection {
  padding: 10px 0px 0px 0px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border: none;
}
ul.ColVis_collection li {
  background: transparent !important;
  padding: 3px 10px !important;
  border: none !important;
  box-shadow: none !important;
}
#datatable-colvid_info {
  float: left;
}
/* =============
   Widget
============= */
/* Widget-box styles */
.widget-box-1 i.inform {
  font-size: 20px;
  cursor: pointer;
}
.widget-box-1 h4 {
  margin-bottom: 5px;
  margin-top: 0px;
}
.widget-box-1 h2 {
  margin: 20px;
  font-weight: 600;
}
.widget-box-1 p {
  margin-bottom: 0px;
}
.widget-s-1 {
  border-radius: 6px;
}
/* Widget (background-icon) */
.widget-bg-color-icon .bg-icon {
  height: 80px;
  width: 80px;
  text-align: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  background-clip: padding-box;
}
.widget-bg-color-icon .bg-icon i {
  font-size: 32px;
  line-height: 80px;
}
.widget-bg-color-icon .bg-icon-info {
  background-color: rgba(52, 211, 235, 0.2);
  border: 1px solid #34d3eb;
}
.widget-bg-color-icon .bg-icon-primary {
  background-color: rgba(93, 156, 236, 0.2);
  border: 1px solid #5d9cec;
}
.widget-bg-color-icon .bg-icon-pink {
  background-color: rgba(251, 109, 157, 0.2);
  border: 1px solid #fb6d9d;
}
.widget-bg-color-icon .bg-icon-purple {
  background-color: rgba(114, 102, 186, 0.2);
  border: 1px solid #7266ba;
}
.widget-bg-color-icon .bg-icon-success {
  background-color: rgba(129, 200, 104, 0.2);
  border: 1px solid #81c868;
}
.widget-bg-color-icon .bg-icon-custom {
  background-color: rgba(95, 190, 170, 0.2);
  border: 1px solid #5fbeaa;
}
.widget-bg-color-icon .bg-icon-warning {
  background-color: rgba(255, 189, 74, 0.2);
  border: 1px solid #ffbd4a;
}
.widget-bg-color-icon .bg-icon-danger {
  background-color: rgba(240, 80, 80, 0.2);
  border: 1px solid #f05050;
}
.widget-bg-color-icon .bg-icon-inverse {
  background-color: rgba(76, 86, 103, 0.2);
  border: 1px solid #4c5667;
}
.mini-stat-icon {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-block;
  float: left;
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  text-align: center;
  width: 60px;
}
.mini-stat-info {
  padding-top: 2px;
  color: #eeeeee;
}
.mini-stat-info span {
  color: #ffffff;
  display: block;
  font-size: 24px;
  font-weight: 600;
}
.mini-stat-info span.name {
  color: #ffffff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
}
.widget-inline {
  padding: 20px 0px !important;
}
.widget-inline .col-lg-3 {
  padding: 0px;
}
.widget-inline .widget-inline-box {
  border-right: 1px solid #e3e8f1;
  padding: 20px;
}
.widget-inline .widget-inline-box i {
  font-size: 32px;
}
/* Inbox-widget */
.inbox-widget .inbox-item {
  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
}
.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}
.inbox-widget .inbox-item img {
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
  color: #333333;
  display: block;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-text {
  color: #a0a0a0;
  display: block;
  font-size: 12px;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
  color: #a9a9a9;
  font-size: 11px;
  position: absolute;
  right: 7px;
  top: 2px;
}
/* Chat widget */
.conversation-list {
  list-style: none;
  height: 332px;
  padding: 0px 20px;
}
.conversation-list li {
  margin-bottom: 24px;
}
.conversation-list .chat-avatar {
  display: inline-block;
  float: left;
  text-align: center;
  width: 42px;
}
.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}
.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}
.conversation-list .ctext-wrap {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background: #f4f8fb;
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.conversation-list .ctext-wrap i {
  color: #1a2942;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  position: relative;
}
.conversation-list .ctext-wrap p {
  margin: 0px;
  padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
  right: 100%;
  top: 0%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #f4f8fb;
  border-width: 8px;
  margin-left: -1px;
  border-right-color: #f4f8fb;
}
.conversation-list .conversation-text {
  display: inline-block;
  float: left;
  font-size: 12px;
  margin-left: 12px;
  width: 70%;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}
.conversation-list .odd .ctext-wrap:after {
  border-color: rgba(238, 238, 242, 0) !important;
  border-left-color: #f4f8fb !important;
  border-top-color: #f4f8fb !important;
  left: 100% !important;
  margin-right: -1px;
}
.chat-send {
  padding-left: 0px;
  padding-right: 30px;
}
.chat-send button {
  width: 100%;
}
.chat-inputbar {
  padding-left: 30px;
}
/* Todos widget */
#todo-message {
  font-size: 16px;
}
.todo-list li {
  border-bottom: 1px solid #eeeeee;
  border-radius: 0px;
  border: 0px;
  margin: 0px;
  padding: 1px;
  color: #98a6ad;
}
.todo-list li:last-of-type {
  border-bottom: none;
}
.todo-send {
  padding-left: 0px;
}
/* Widget-chart */
.widget-chart ul li {
  width: 31.5%;
  display: inline-block;
  padding: 0px;
}
.widget-panel {
  padding: 30px 20px;
  padding-left: 30px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
}
.widget-panel i {
  font-size: 60px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  line-height: 60px;
}
.widget-style-2 i {
  background: rgba(244, 248, 251, 0.6) !important;
  font-size: 48px;
  padding: 30px 40px;
}
/* Google maps */
.gmap iframe {
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
}
.gmap-info:before {
  color: #333333;
  content: "\f041";
  font-family: "FontAwesome";
  font-size: 35px;
  left: 10px;
  position: absolute;
  top: 8px;
}
.gmap-info {
  float: left;
  padding: 0 20px 0 50px;
  position: relative;
}
.gmap-buttons {
  float: right;
  margin-top: 28px;
}
.gmap-buttons .btn {
  margin-left: 3px;
}
/* Google maps ends */
/* Table with Action */
.table-actions-bar tr td {
  vertical-align: middle !important;
}
.table-actions-bar .table-action-btn {
  color: #98a6ad;
  display: inline-block;
  width: 28px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 20px;
}
.table-actions-bar .table-action-btn:hover {
  color: #5fbeaa;
  border-color: #5fbeaa;
}
/* Transaction */
.transaction-list li {
  padding: 7px 0px;
  border-bottom: 1px solid #ebeff2;
  clear: both;
  position: relative;
}
.transaction-list i {
  width: 20px;
  position: absolute;
  top: 10px;
  font-size: 12px;
}
.transaction-list .tran-text {
  padding-left: 25px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 115px;
}
.transaction-list .tran-price {
  margin-left: 30px;
}
/* Friend list */
.friend-list a {
  margin: 5px;
  display: inline-block;
}
.friend-list .extra-number {
  height: 48px;
  width: 48px;
  display: block;
  line-height: 48px;
  color: #98a6ad;
  font-weight: 700;
  background-color: #ebeff2;
  border-radius: 50%;
  border: 1px solid #98a6ad;
}
/* Profile widget */
.profile-widget .bg-profile {
  height: 125px;
}
.profile-widget .img-thumbnail {
  margin-top: -42px;
  border: none;
}
.profile-widget .widget-list {
  padding: 10px;
  margin-top: 20px;
  margin-left: 0px;
  padding-bottom: 30px;
}
.profile-widget .widget-list span {
  display: block;
  font-weight: bold;
  font-size: 18px;
}
/*  Bar widget */
.bar-widget .iconbox {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin-right: 20px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  background-clip: padding-box;
  color: #ffffff;
  text-align: center;
}
.bar-widget .iconbox i {
  line-height: 50px;
  font-size: 20px;
}
/* Social type widgets */
.widget-profile-one .card-box {
  border-radius: 0px;
}
.widget-profile-one .card-box:first-of-type {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.widget-profile-one .card-box:last-of-type {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.social-feed-box h3 {
  font-size: 18px;
  font-style: italic;
}
.widget-box-two {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.social-feed-slider {
  padding-bottom: 50px;
}
.social-feed-slider .carousel-indicators {
  bottom: 0px;
}
.pro-widget-img {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  padding: 70px 0px;
  background-size: cover;
  background: url("../images/big/bg.jpg") center right no-repeat;
}
/* Blog styles */
.blog-box-one {
  margin-bottom: 20px;
}
.blog-box-one .cover-wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 1;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.blog-box-one .cover-wrapper img {
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.blog-box-one .cover-wrapper:hover img {
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
.blog-box-one .post-info {
  background-color: #ffffff;
  padding: 20px;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.blog-box-one .post-info .date {
  float: left;
  border-bottom: 3px solid #71b6f9 ;
}
.post-info .date .day {
  font-size: 22px;
  color: #333;
  font-weight: 600;
  line-height: 22px;
}
.post-info .date .month {
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: inline-block;
}
.blog-box-one .meta {
  padding-left: 0px;
}
.blog-box-one .post-info .meta-container {
  padding-left: 55px;
}
.blog-box-one .post-info p {
  margin-top: 15px;
}
.blog-widget-action a {
  color: #98a6ad;
  display: inline-block;
  margin-right: 10px;
}
/* =============
   Form elements
============= */
.error {
  color: #f05050;
  font-size: 12px;
  font-weight: 500;
}
.datepicker {
  padding: 8px;
}
.datepicker th {
  font-size: 14px !important;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: none;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #5fbeaa;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #5fbeaa !important;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
}
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  background-color: #fafafa;
}
.datepicker-inline {
  border: 2px solid #eeeeee;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #5d9cec;
  border-color: #5d9cec;
}
.daterangepicker .input-mini.active {
  border: 1px solid #AAAAAA;
}
.daterangepicker .ranges li {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  background-clip: padding-box;
  color: #36404a;
  font-weight: 600;
  font-size: 12px;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  border: 1px solid #e3e3e3;
  padding: 2px;
  width: 60px;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background-color: #5d9cec;
  border: 1px solid #5d9cec;
}
.search-input {
  margin-bottom: 10px;
}
.ms-selectable {
  box-shadow: none;
  outline: none !important;
}
.ms-container .ms-list.ms-focus {
  box-shadow: none;
}
.ms-container .ms-selectable li.ms-hover {
  background-color: #5d9cec;
}
.ms-container .ms-selection li.ms-hover {
  background-color: #5d9cec;
}
.parsley-error {
  border-color: #f05050 !important;
}
.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}
.parsley-errors-list.filled {
  display: block;
}
.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #f6504d;
}
.note-editor {
  border: 1px solid #eeeeee;
  position: relative;
}
.note-editor .note-toolbar {
  background-color: #f4f8fb;
  border-bottom: 1px solid #eeeeee;
  margin: 0;
}
.note-editor .note-statusbar {
  background-color: #ffffff;
}
.note-editor .note-statusbar .note-resizebar {
  border-top: none;
  height: 15px;
  padding-top: 3px;
}
.note-popover .popover .popover-content {
  padding: 5px 0 10px 5px;
}
.note-toolbar {
  padding: 5px 0 10px 5px;
}
.code-edit-wrap {
  padding: 0px !important;
}
.cm-s-ambiance .CodeMirror-linenumber {
  color: #bcbcbc;
}
.cm-s-ambiance .CodeMirror-gutters {
  background-color: #4c5667 !important;
  box-shadow: none;
}
.cm-s-ambiance.CodeMirror {
  background-color: #4c5667 !important;
  box-shadow: none;
}
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  color: #5d9cec;
  text-decoration: none;
}
.editor-horizontal .popover-content {
  padding: 9px 30px;
}
.wizard > .content {
  background: #ffffff;
  min-height: 240px;
  padding: 20px;
}
.wizard > .content > .body {
  padding: 0px;
  position: relative;
}
.wizard > .content > .body input {
  border: 1px solid #E3E3E3;
}
.wizard > .content > .body ul > li {
  display: block;
  line-height: 30px;
}
.wizard > .content > .body label.error {
  color: #f05050;
  margin-left: 0;
}
.wizard > .content > .body label {
  display: inline-block;
  margin-top: 10px;
}
.wizard > .steps .number {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  line-height: 30px;
  margin-right: 10px;
  width: 30px;
  text-align: center;
}
.wizard > .steps .disabled a {
  background: #ffffff;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}
.wizard > .steps .disabled a:hover {
  background: #f9f9f9;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}
.wizard > .steps .disabled a:active {
  background: #f9f9f9;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}
.wizard > .steps .current a {
  background: #5fbeaa;
}
.wizard > .steps .current a:hover {
  background: #5fbeaa;
}
.wizard > .steps .current a:hover .number {
  color: #ffffff;
}
.wizard > .steps .current a:active {
  background: #5fbeaa;
}
.wizard > .steps .current a:active .number {
  color: #ffffff;
}
.wizard > .steps .current a .number {
  color: #ffffff;
}
.wizard > .steps .done a {
  background: #7a8c9a;
}
.wizard > .steps .done a:hover {
  background: #7a8c9a;
}
.wizard > .steps .done a:active {
  background: #7a8c9a;
}
.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active,
.wizard > .content {
  border-radius: 2px;
}
.wizard > .actions a {
  background: #5fbeaa;
  border-radius: 2px;
  color: #ffffff;
}
.wizard > .actions a:hover {
  background: #5fbeaa;
  border-radius: 2px;
  color: #ffffff;
}
.wizard > .actions a:active {
  background: #5fbeaa;
  border-radius: 2px;
  color: #ffffff;
}
.wizard > .actions .disabled a {
  background: #ffffff;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}
.wizard > .actions .disabled a:hover {
  background: #f9f9f9;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}
.wizard > .actions .disabled a:active {
  background: #f9f9f9;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}
/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 6px;
}
.dropzone .dz-message {
  font-size: 30px;
}
/* X-Editable */
.editable-click,
a.editable-click,
a.editable-click:hover {
  border: none;
}
/* AUTOCOMPLETE */
.autocomplete-suggestions {
  border: 1px solid #f9f9f9;
  background: #ffffff;
  cursor: default;
  overflow: auto;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
}
.autocomplete-no-suggestion {
  padding: 2px 5px;
}
.autocomplete-selected {
  background: rgba(152, 166, 173, 0.3);
  cursor: pointer;
}
.autocomplete-suggestions strong {
  font-weight: bold;
  color: #36404a;
}
.autocomplete-group {
  padding: 2px 5px;
}
.autocomplete-group strong {
  font-weight: bold;
  font-size: 16px;
  color: #36404a;
  display: block;
}
/* Image crop */
.img-container,
.img-preview {
  background-color: #f7f7f7;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.img-container {
  min-height: 200px;
  max-height: 466px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .img-container {
    min-height: 466px;
  }
}
.img-container > img {
  max-width: 100%;
}
.docs-preview {
  margin-right: -15px;
  margin-bottom: 10px;
}
.img-preview {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
.img-preview > img {
  max-width: 100%;
}
/* =============
   Sweet Alert
============= */
.sweet-alert p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
button.confirm {
  background-color: #5fbeaa !important;
  box-shadow: none !important;
}
